const defaultLogo = '/assets/images/default.png'

export const layoutConfig = () => {
    return {
        appLogo: defaultLogo,
        selectPrimary25: '#1AA7FF1a', // for option hover bg-color
        selectPrimary: '#1AA7FF', // for selected option bg-color
        selectNeutral10: '#1AA7FF', // for tags bg-color
        selectNeutral20: '#ededed', // for input border-color
        selectNeutral30: '#ededed' // for input hover border-color
    }
}