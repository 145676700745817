const SpinnerComponent = () => {

    const logo = localStorage.getItem('appLogo')
    return (
        <div className='fallback-spinner app-loader'>
            {logo &&
                <img className='fallback-logo' src={logo} alt='logo'/>
            }
            <div className='loading'>
                <div className='effect-1 effects'></div>
                <div className='effect-2 effects'></div>
                <div className='effect-3 effects'></div>
            </div>
        </div>
    )
}

export default SpinnerComponent
