// ** Redux Imports
import {createSlice} from '@reduxjs/toolkit'

export const loadersSlice = createSlice({
    name: 'loaders',
    initialState: {
        spinner: false
    },
    reducers: {
        toggleSpinner: (state, action) => {
            state.spinner = action.payload
        }
    }
})

export const {
    toggleSpinner
} = loadersSlice.actions

export default loadersSlice.reducer
