import {createSlice} from "@reduxjs/toolkit"

export const parkingOperatorSlice = createSlice({
    name: 'priceList',
    initialState: {
        priceListModalId: null,
        priceListModalState: false,
        priceListManagementModalState: false,
        priceListFormLoad: 0
    },
    reducers: {
        setPriceListModalState: (state, action) => {
            state.priceListModalState = action.payload
        },
        setPriceListManagementModalState: (state, action) => {
            state.priceListManagementModalState = action.payload
        },
        setPriceListModalId: (state, action) => {
            state.priceListModalId = action.payload
        },
        incrementPriceListFormLoad: (state) => {
            state.priceListFormLoad = state.priceListFormLoad + 1
        }
    }
})

export default parkingOperatorSlice.reducer

export const {
    setPriceListModalState,
    setPriceListManagementModalState,
    incrementPriceListFormLoad,
    setPriceListModalId
} = parkingOperatorSlice.actions
