import axios from 'axios'
import jwtDefaultConfig from './jwtDefaultConfig'
import i18n from "i18next"
import {store} from "@src/redux/store"
import {handleLogout} from '@store/authentication'
import {toggleSpinner} from '@store/loaders'

export default class JwtService {
    // ** jwtConfig <= Will be used by this service
    jwtConfig = {...jwtDefaultConfig}

    // ** For Refreshing Token
    isAlreadyFetchingAccessToken = false

    // ** For Refreshing Token
    subscribers = []

    constructor(jwtOverrideConfig) {
        this.jwtConfig = {...this.jwtConfig, ...jwtOverrideConfig}
        this.axios = axios

        // ** Request Interceptor
        axios.interceptors.request.use(
            config => {
                // ** Get token from localStorage
                const accessToken = this.getToken()
                config.headers["Content-Language"] = i18n.language

                // ** If token is present add it to request's Authorization Header
                if (accessToken && process.env.REACT_APP_AUTHENTICATE_WITH_TOKEN_ON_HEADER) {
                    const parsedToken = JSON.parse(accessToken)
                    // ** eslint-disable-next-line no-param-reassign
                    config.headers.Authorization = `${this.jwtConfig.tokenType} ${parsedToken}`
                }
                return config
            },
            error => Promise.reject(error)
        )

        // ** Add request/response interceptor
        axios.interceptors.response.use(
            response => response,
            error => {
                // ** const { config, response: { status } } = error
                const {config, response} = error
                const originalRequest = config

                // ** if (status === 401) {
                if (response && response.status === 401) {

                    if (this.isAlreadyFetchingAccessToken) {
                        const url = response?.config?.url
                        if (url && url.indexOf('/api/token/refresh') !== -1) {
                            store.dispatch(handleLogout())
                            if (!window?.location?.pathname || window.location.pathname !== '/login') {
                                store.dispatch(toggleSpinner(true))
                                this.logout().then(() => {
                                    window.location.href = '/login'
                                }).catch((err) => {
                                    if (err?.response?.status === 403) {
                                        window.location.href = '/login'
                                    }
                                })
                            }
                        }
                    }

                    if (!this.isAlreadyFetchingAccessToken) {
                        this.isAlreadyFetchingAccessToken = true
                        this.refreshToken()
                            .then(r => {
                                this.isAlreadyFetchingAccessToken = false

                                if (parseInt(process.env.REACT_APP_AUTHENTICATE_WITH_TOKEN_ON_HEADER) === 1) {
                                    // // ** Update accessToken in localStorage
                                    this.setToken(JSON.stringify(r.data.token))
                                    this.setRefreshToken(JSON.stringify(r.data.refresh_token))
                                }
                                window.localStorage.setItem('userData', JSON.stringify(r.data.userData))
                                this.onAccessTokenFetched(r.data.accessToken)
                            })
                    }
                    const retryOriginalRequest = new Promise(resolve => {
                        this.addSubscriber(accessToken => {
                            // ** Make sure to assign accessToken according to your response.
                            // ** Check: https://pixinvent.ticksy.com/ticket/2413870
                            // ** Change Authorization header
                            if (parseInt(process.env.REACT_APP_AUTHENTICATE_WITH_TOKEN_ON_HEADER) === 1) {
                                originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
                            }

                            resolve(this.axios(originalRequest))
                        })
                    })
                    return retryOriginalRequest
                }
                return Promise.reject(error)
            }
        )
    }

    onAccessTokenFetched(accessToken) {
        this.subscribers = this.subscribers.filter(callback => callback(accessToken))
    }

    addSubscriber(callback) {
        this.subscribers.push(callback)
    }

    getToken() {
        return localStorage.getItem(this.jwtConfig.storageTokenKeyName)
    }

    getRefreshToken() {
        return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName)
    }

    setToken(value) {
        localStorage.setItem(this.jwtConfig.storageTokenKeyName, value)
    }

    setRefreshToken(value) {
        localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value)
    }

    login(...args) {
        return axios.post(
            `${process.env.REACT_APP_BASE_API_URL}${this.jwtConfig.loginEndpoint}`,
            ...args,
            {withCredentials: true}
        )
    }

    register(...args) {
        return axios.post(`${process.env.REACT_APP_BASE_API_URL}${this.jwtConfig.registerEndpoint}`, ...args)
    }

    logout() {
        return axios.post(`${process.env.REACT_APP_BASE_API_URL}${this.jwtConfig.logoutEndpoint}`, {}, {
            withCredentials: true
        })
    }

    refreshToken() {
        const data = {}
        if (parseInt(process.env.REACT_APP_AUTHENTICATE_WITH_TOKEN_ON_HEADER) === 1) {
            data.refreshToken = JSON.parse(this.getRefreshToken())
        }
        return axios.post(`${process.env.REACT_APP_BASE_API_URL}${this.jwtConfig.refreshEndpoint}`, data, {
            withCredentials: true
        })
    }

    axiosPost(path, ...args) {
        if (args.length === 0) {
            args.push({data: {}})
        }
        if (args[1] !== undefined) {
            args[1] = {
                withCredentials: true,
                ...args[1]
            }
        } else {
            args[1] = {
                withCredentials: true
            }
        }
        return axios.post(`${process.env.REACT_APP_BASE_API_URL}${path}`, ...args)
    }

    axiosGet(path, config) {
        return axios.get(`${process.env.REACT_APP_BASE_API_URL}${path}`, {withCredentials: true, ...config})
    }

    baseApiPath() {
        return `${process.env.REACT_APP_BASE_API_URL}`
    }
}
