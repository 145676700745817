import layout from './layout'
import auth from './authentication'
import parkingOperator from '@src/pages/parking/parking-operator/store'
import newReservation from '@src/pages/reservation/new-reservation/store'
import reservationManagement from '@src/pages/reservation/management/store'
import reservation from '@src/pages/reservation/store'
import userList from '@src/pages/administration/user/store'
import entryExitEventReport from '@src/pages/report/entry-exit-event-report/store'
import priceList from '@src/pages/parking/price-list/store'
import loaders from './loaders'

const rootReducer = {
    auth,
    layout,
    parkingOperator,
    newReservation,
    reservationManagement,
    reservation,
    userList,
    entryExitEventReport,
    priceList,
    loaders
}

export default rootReducer
