import {createSlice} from "@reduxjs/toolkit"

export const reservationManagementSlice = createSlice({
    name: 'reservationManagement',
    initialState: {
        tableBlock: false,
        formLoad: 1,
        reservationCancelModalState: false,
        reservationCancelModalSelected: null
    },
    reducers: {
        incrementFormLoad: (state) => {
            state.formLoad = state.formLoad + 1
        },
        setTableBlock: (state, action) => {
            state.tableBlock = action.payload
        },
        setReservationCancelModalState: (state, action) => {
            state.reservationCancelModalState = action.payload
        },
        setReservationCancelModalSelected: (state, action) => {
            state.reservationCancelModalSelected = action.payload
        }
    }
})

export default reservationManagementSlice.reducer

export const {
    incrementFormLoad,
    setTableBlock,
    setReservationCancelModalState,
    setReservationCancelModalSelected
} = reservationManagementSlice.actions
