import {createSlice} from "@reduxjs/toolkit"

export const parkingOperatorSlice = createSlice({
    name: 'parkingOperator',
    initialState: {
        tableBlock: false,
        formLoad: 1,
        passwordChangeModalState: false,
        passwordChangeSelectedOperator: null,
        passwordChangeBlockState: false,
        passwordChangeFormErrors: [],
        modalState: false,
        modalSelectedOperator: null,
        modalBlockState: false,
        modalFormErrors: [],
        modalDataLoaded: false
    },
    reducers: {
        incrementFormLoad: (state) => {
            state.formLoad = state.formLoad + 1
        },
        setTableBlock: (state, action) => {
            state.tableBlock = action.payload
        },
        setPasswordChangeModalState: (state, action) => {
            state.passwordChangeModalState = action.payload
            state.passwordChangeFormErrors = []
            state.passwordChangeBlockState = false
            if (!action.payload) {
                state.passwordChangeSelectedOperator = null
            }
        },
        setPasswordChangeSelectedOperator: (state, action) => {
            state.passwordChangeSelectedOperator = action.payload
        },
        setPasswordChangeBlockState: (state, action) => {
            state.passwordChangeBlockState = action.payload
        },
        setPasswordChangeFormErrors: (state, action) => {
            state.passwordChangeFormErrors = action.payload
        },
        setModalState: (state, action) => {
            state.modalState = action.payload
            state.modalFormErrors = []
            state.modalBlockState = false
            if (!action.payload) {
                state.modalSelectedOperator = null
            }
        },
        setSelectedOperator: (state, action) => {
            state.modalSelectedOperator = action.payload
        },
        setModalBlockState: (state, action) => {
            state.modalBlockState = action.payload
        },
        setModalFormErrors: (state, action) => {
            state.modalFormErrors = action.payload
        },
        setModalDataLoaded: (state, action) => {
            state.modalDataLoaded = action.payload
        }
    }
})

export default parkingOperatorSlice.reducer

export const {
    incrementFormLoad,
    setTableBlock,
    setPasswordChangeModalState,
    setPasswordChangeSelectedOperator,
    setPasswordChangeBlockState,
    setPasswordChangeFormErrors,
    setModalState,
    setSelectedOperator,
    setModalBlockState,
    setModalFormErrors,
    setModalDataLoaded
} = parkingOperatorSlice.actions
