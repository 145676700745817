import {createSlice} from "@reduxjs/toolkit"

export const reservationSlice = createSlice({
    name: 'newReservation',
    initialState: {
        step: 1,
        formErrors: [],
        parkings: [],
        isInvoiceData: true,
        isWithoutPayment: true,
        isReservationOnlyDate: true,
        selectedParking: null,
        totalPrice: 0,
        averageDayPrice: 0,
        discount: 0,
        discountBaseValue: 0,
        discountType: null,
        discountPercentageValue: 0,
        discountPercentage: 0,
        blockReservation: false,
        totalPriceAfterDiscount: 0,
        reservationUserEmail: null,
        hasFlightData: false,
        reservationConfirmed: false,
        discountCode: null,
        reservationConfirmedData: {},
        priceListErrors: [],
        entryEventWithoutExitDate: null,
        hasReservationInTerm: null,
        blockActionButtons: {
            prev: false,
            next: false
        },
        reservationDataRequest: {
            priceList: false,
            reservationInTerm: false,
            entryEventWithoutExit: false
        },
        initialApiCalls: {
            parkingData: false, accountData: false, reservationData: false
        }
    },
    reducers: {
        resetState: (state) => {
            state.step = 1
            state.formErrors = []
            state.parkings = []
            state.isInvoiceData = false
            state.isWithoutPayment = false
            state.isReservationOnlyDate = true
            state.selectedParking = null
            state.reservationLoaded = false
            state.totalPrice = 0
            state.averageDayPrice = 0
            state.discount = 0
            state.discountBaseValue = 0
            state.discountType = null
            state.discountPercentageValue = 0
            state.discountPercentage = 0
            state.blockReservation = false
            state.totalPriceAfterDiscount = 0
            state.reservationUserEmail = null
            state.hasFlightData = false
            state.reservationConfirmed = false
            state.discountCode = null
            state.reservationConfirmedData = {}
            state.priceListErrors = []
            state.entryEventWithoutExitDate = null
            state.hasReservationInTerm = null
            state.blockActionButtons = {
                prev: false,
                next: false
            }
            state.reservationDataRequest = {
                priceList: false,
                reservationInTerm: false,
                entryEventWithoutExit: false
            }
            state.initialApiCalls = {
                parkingData: false, accountData: false, reservationData: false
            }
        },
        setParkings: (state, action) => {
            state.parkings = action.payload
        },
        setSelectedParking: (state, action) => {
            const parking = state.parkings.find(parking => parking.id === parseInt(action.payload))
            state.selectedParking = parking
            state.hasFlightData = parking?.parkingParameter?.hasFlightDataInReservation ?? false
        },
        setBlockReservation: (state, action) => {
            state.blockReservation = action.payload
        },
        setTotalPrice: (state, action) => {
            state.totalPrice = action.payload
        },
        setTotalPriceAfterDiscount: (state, action) => {
            state.totalPriceAfterDiscount = action.payload
        },
        setReservationUserEmail: (state, action) => {
            state.reservationUserEmail = action.payload
        },
        setReservationConfirmed: (state, action) => {
            state.reservationConfirmed = action.payload
        },
        setDiscountPercentage: (state, action) => {
            state.discountPercentage = action.payload
        },
        setDiscountPercentageValue: (state, action) => {
            state.discountPercentageValue = action.payload
        },
        setDiscount: (state, action) => {
            state.discount = action.payload
        },
        setDiscountBaseValue: (state, action) => {
            state.discountBaseValue = action.payload
        },
        setDiscountType: (state, action) => {
            state.discountType = action.payload
        },
        setDiscountCode: (state, action) => {
            state.discountCode = action.payload
        },
        setReservationConfirmedData: (state, action) => {
            state.reservationConfirmedData = action.payload
        },
        setStep: (state, action) => {
            state.step = action.payload
        },
        setInitialApiCalls: (state, action) => {
            state.initialApiCalls[action.payload.type] = action.payload.state
        },
        setFormErrors: (state, action) => {
            state.formErrors = action.payload
        },
        setAverageDayPrice: (state, action) => {
            state.averageDayPrice = action.payload
        },
        setPriceListErrors: (state, action) => {
            state.priceListErrors = action.payload
        },
        setIsWithoutPayment: (state, action) => {
            state.isWithoutPayment = action.payload
            state.isInvoiceData = !action.payload
        },
        setHasEntryEventWithoutExit: (state, action) => {
            state.hasEntryEventWihtoutExit = action.payload
        },
        setEntryEventWithoutExitDate: (state, action) => {
            state.entryEventWithoutExitDate = action.payload
        },
        setHasReservationInTerm: (state, action) => {
            state.hasReservationInTerm = action.payload
        },
        setBlockActionButtons: (state, action) => {
            state.blockActionButtons = action.payload
        },
        setReservationDataRequest: (state, action) => {
            state.reservationDataRequest = action.payload
        }
    }
})

export default reservationSlice.reducer

export const {
    resetState,
    setParkings,
    setSelectedParking,
    setBlockReservation,
    setTotalPrice,
    setReservationUserEmail,
    setTotalPriceAfterDiscount,
    setReservationConfirmed,
    setDiscountCode,
    setDiscount,
    setDiscountBaseValue,
    setDiscountType,
    setReservationConfirmedData,
    setDiscountPercentage,
    setDiscountPercentageValue,
    setStep,
    setInitialApiCalls,
    setFormErrors,
    setAverageDayPrice,
    setPriceListErrors,
    setIsWithoutPayment,
    setEntryEventWithoutExitDate,
    setHasReservationInTerm,
    setBlockActionButtons,
    setReservationDataRequest
} = reservationSlice.actions
