import {createSlice} from "@reduxjs/toolkit"

export const reservationSlice = createSlice({
    name: 'reservation',
    initialState: {
        reservationDetailsModalState: false,
        reservationDetailsSelected: null,
        modalDetailsBlockState: false
    },
    reducers: {
        setReservationDetailsModalState: (state, action) => {
            state.reservationDetailsModalState = action.payload
        },
        setReservationDetailsSelected: (state, action) => {
            state.reservationDetailsSelected = action.payload
        },
        setModalDetailsBlockState: (state, action) => {
            state.modalDetailsBlockState = action.payload
        }
    }
})

export default reservationSlice.reducer

export const {
    setReservationDetailsModalState,
    setReservationDetailsSelected,
    setModalDetailsBlockState
} = reservationSlice.actions
