import {Ability, AbilityBuilder} from '@casl/ability'
import {initialAbility} from './initialAbility'

//  Read ability from localStorage
// * Handles auto fetching previous abilities if already logged in user
// ? You can update this if you store user abilities to more secure place
// ! Anyone can update localStorage so be careful and please update this
const userData = JSON.parse(localStorage.getItem('userData'))
const existingAbility = userData ? JSON.parse(localStorage.getItem('userAbility')) : null

export const updateAbility = (ability, roles) => {

    const {can, rules} = new AbilityBuilder()
    const abilityArray = []

    roles.forEach(role => {
        can(role, 'IS_GRANTED')
        abilityArray.push({
            action: role,
            resource: 'IS_GRANTED'
        })
    })

    localStorage.setItem('userAbility', JSON.stringify(abilityArray))
    ability.update(rules)
}

export default new Ability(existingAbility || initialAbility)
