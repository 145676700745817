// ** Redux Imports
import {createSlice} from '@reduxjs/toolkit'

// ** UseJWT import to get config
import useJwt from '@src/auth/jwt/useJwt'

const config = useJwt.jwtConfig

const initialUser = () => {
    const item = window.localStorage.getItem('userData')
    //** Parse stored json or if none return initialValue
    return item ? JSON.parse(item) : {}
}

export const authSlice = createSlice({
    name: 'authentication',
    initialState: {
        userData: initialUser()
    },
    reducers: {
        handleLogin: (state, action) => {
            state.userData = action.payload
            localStorage.setItem('userData', JSON.stringify(action.payload))
            localStorage.setItem('isLoggedIn', 1)
            if (parseInt(process.env.REACT_APP_AUTHENTICATE_WITH_TOKEN_ON_HEADER) === 1) {
                state[config.storageTokenKeyName] = action.payload[config.storageTokenKeyName]
                state[config.storageRefreshTokenKeyName] = action.payload[config.storageRefreshTokenKeyName]
                localStorage.setItem(config.storageTokenKeyName, JSON.stringify(action.payload.accessToken))
                localStorage.setItem(config.storageRefreshTokenKeyName, JSON.stringify(action.payload.refreshToken))
            }
        },
        handleLogout: state => {
            state.userData = {}
            state[config.storageTokenKeyName] = null
            state[config.storageRefreshTokenKeyName] = null
            // ** Remove user, accessToken & refreshToken from localStorage
            localStorage.removeItem('userData')
            localStorage.removeItem('isLoggedIn')
            localStorage.removeItem('userAbility')
            localStorage.removeItem(config.storageTokenKeyName)
            localStorage.removeItem(config.storageRefreshTokenKeyName)
        },
        handleUserNameChange: (state, action) => {
            const userData = initialUser()
            userData.firstName = action.payload.firstName
            userData.surname = action.payload.surname
            window.localStorage.setItem('userData', JSON.stringify(userData))

            state.userData.firstName = action.payload.firstName
            state.userData.surname = action.payload.surname
        }
    }
})

export const {
    handleLogin,
    handleLogout,
    handleUserNameChange
} = authSlice.actions

export default authSlice.reducer
