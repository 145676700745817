import {createSlice} from "@reduxjs/toolkit"

export const entryExitEventReportSlice = createSlice({
    name: 'entryExitEventReport',
    initialState: {
        imageOpen: false,
        cameraEvent: null,
        formLoad: 0,
        isCountedModalState: false,
        isCountedModalSelectedEvent: null,
        isCountedModalHasReservation: false
    },
    reducers: {
        setCameraEvent: (state, action) => {
            state.cameraEvent = action.payload
            state.imageOpen = null !== action.payload
        },
        setIsCountedModalState: (state, action) => {
            state.isCountedModalState = action.payload
        },
        setIsCountedModalSelectedEvent: (state, action) => {
            state.isCountedModalSelectedEvent = action.payload
        },
        setIsCountedModalHasReservation: (state, action) => {
            state.isCountedModalHasReservation = action.payload
        },
        incrementFormLoad: (state) => {
            state.formLoad = state.formLoad + 1
        }
    }
})

export default entryExitEventReportSlice.reducer

export const {
    setCameraEvent,
    setIsCountedModalState,
    setIsCountedModalSelectedEvent,
    setIsCountedModalHasReservation,
    incrementFormLoad
} = entryExitEventReportSlice.actions
