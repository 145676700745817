import {createSlice} from "@reduxjs/toolkit"

export const userListSlice = createSlice({
    name: 'parkingOperator',
    initialState: {
        tableBlock: false,
        formLoad: 1,
        passwordChangeModalState: false,
        passwordChangeSelectedUser: null,
        passwordChangeBlockState: false,
        passwordChangeFormErrors: [],
        settlementModalState: false,
        settlementSelectedUser: null,
        settlementBlockState: false,
        settlementFormErrors: [],
        permissionModalState: false,
        permissionSelectedUser: null,
        permissionSelectedCompany: null,
        permissionBlockState: false,
        permissionFormErrors: [],
        roleModalState: false,
        roleSelectedUser: null,
        roleBlockState: false,
        roleFormErrors: [],
        modalState: false,
        modalSelectedUser: null,
        modalBlockState: false,
        modalFormErrors: [],
        modalDataLoaded: false,
        modalDetailsState: false,
        modalDetailsBlockState: false
    },
    reducers: {
        incrementFormLoad: (state) => {
            state.formLoad = state.formLoad + 1
        },
        setTableBlock: (state, action) => {
            state.tableBlock = action.payload
        },
        setPasswordChangeModalState: (state, action) => {
            state.passwordChangeModalState = action.payload
            state.passwordChangeFormErrors = []
            state.passwordChangeBlockState = false
            if (!action.payload) {
                state.passwordChangeSelectedUser = null
            }
        },
        setPasswordChangeSelectedUser: (state, action) => {
            state.passwordChangeSelectedUser = action.payload
        },
        setPasswordChangeBlockState: (state, action) => {
            state.passwordChangeBlockState = action.payload
        },
        setPasswordChangeFormErrors: (state, action) => {
            state.passwordChangeFormErrors = action.payload
        },

        setSettlementModalState: (state, action) => {
            state.settlementModalState = action.payload
            state.settlementFormErrors = []
            state.settlementBlockState = false
            if (!action.payload) {
                state.settlementSelectedUser = null
            }
        },
        setSettlementSelectedUser: (state, action) => {
            state.settlementSelectedUser = action.payload
        },
        setSettlementBlockState: (state, action) => {
            state.settlementBlockState = action.payload
        },
        setSettlementFormErrors: (state, action) => {
            state.settlementFormErrors = action.payload
        },
        setPermissionModalState: (state, action) => {
            state.permissionModalState = action.payload
            state.permissionFormErrors = []
            state.permissionBlockState = false
            if (!action.payload) {
                state.permissionSelectedUser = null
            }
        },
        setPermissionSelectedUser: (state, action) => {
            state.permissionSelectedUser = action.payload
        },
        setPermissionSelectedCompany: (state, action) => {
            state.permissionSelectedCompany = action.payload
        },
        setPermissionBlockState: (state, action) => {
            state.permissionBlockState = action.payload
        },
        setPermissionFormErrors: (state, action) => {
            state.permissionFormErrors = action.payload
        },
        setRoleModalState: (state, action) => {
            state.roleModalState = action.payload
            state.roleFormErrors = []
            state.roleBlockState = false
            if (!action.payload) {
                state.roleSelectedUser = null
            }
        },
        setRoleSelectedUser: (state, action) => {
            state.roleSelectedUser = action.payload
        },
        setRoleBlockState: (state, action) => {
            state.roleBlockState = action.payload
        },
        setRoleFormErrors: (state, action) => {
            state.roleFormErrors = action.payload
        },
        setModalState: (state, action) => {
            state.modalState = action.payload
            state.modalFormErrors = []
            state.modalBlockState = false
            if (!action.payload) {
                state.modalSelectedUser = null
            }
        },
        setSelectedUser: (state, action) => {
            state.modalSelectedUser = action.payload
        },
        setModalBlockState: (state, action) => {
            state.modalBlockState = action.payload
        },
        setModalFormErrors: (state, action) => {
            state.modalFormErrors = action.payload
        },
        setModalDataLoaded: (state, action) => {
            state.modalDataLoaded = action.payload
        },
        setModalDetailsState: (state, action) => {
            state.modalDetailsState = action.payload
            state.modalDetailsBlockState = false
            if (!action.payload) {
                state.modalSelectedUser = null
            }
        },
        setModalDetailsBlockState: (state, action) => {
            state.modalDetailsBlockState = action.payload
        }
    }
})

export default userListSlice.reducer

export const {
    incrementFormLoad,
    setTableBlock,
    setPasswordChangeModalState,
    setPasswordChangeSelectedUser,
    setPasswordChangeBlockState,
    setPasswordChangeFormErrors,
    setSettlementModalState,
    setSettlementSelectedUser,
    setSettlementBlockState,
    setSettlementFormErrors,
    setPermissionModalState,
    setPermissionSelectedUser,
    setPermissionSelectedCompany,
    setPermissionBlockState,
    setPermissionFormErrors,
    setRoleModalState,
    setRoleSelectedUser,
    setRoleBlockState,
    setRoleFormErrors,
    setModalState,
    setSelectedUser,
    setModalBlockState,
    setModalFormErrors,
    setModalDataLoaded,
    setModalDetailsState,
    setModalDetailsBlockState
} = userListSlice.actions
