import i18n from "i18next"
import {AVAILABLE_LOCALES, DEFAULT_LOCALE, LOCALE_PROP} from '../configs/i18n'

/**
 * @param {string} lang
 * @returns {string}
 */
export const resolveParamLanguage = lang => {
    const allowed = AVAILABLE_LOCALES.includes(lang)
    return allowed ? lang : DEFAULT_LOCALE
}

/**
 * @param {string} lang - resolved lang by available locales
 */
export const changeLanguage = lang => {
    const resolvedLang = resolveParamLanguage(lang)
    localStorage.setItem(LOCALE_PROP, lang)
    i18n.changeLanguage(resolvedLang)
}

/**
 * @returns {string}
 */
export const resolveLanguage = () => {
    const language = i18n.language
    const allowed = AVAILABLE_LOCALES.includes(language) !== -1
    if (!allowed) {
        changeLanguage(DEFAULT_LOCALE)
    }
    return allowed ? language : DEFAULT_LOCALE
}

/**
 * @returns {string}
 */
export const getRememberedLanguage = () => {
    const lang = localStorage.getItem(LOCALE_PROP)
    if (undefined === lang || null === lang) {
        return DEFAULT_LOCALE
    }
    return resolveParamLanguage(lang)
}

/**
 * @returns {string[]}
 */
export const getAllowedLocales = () => {
    return AVAILABLE_LOCALES
}